import React, { useState, useEffect, useMemo } from 'react';
import {isMobile} from 'react-device-detect';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  Button,
  TermsOfService,
  ReviewBox,
} from '../../components';
import { TopbarContainer } from '../../containers';
import InstaConnectForm from './InstaConnectForm';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import facebookImage from '../../assets/Facebook-1200x630.jpg';
import SectionFeaturedListing from './SectionFeaturedListing/SectionFeaturedListing';
import SectionLoyaltyTestInfo from './SectionLoyaltyTestInfo';
import SectionFAQ from './SectionFAQ';
import twitterImage from '../../assets/Twitter-600x314.jpg';
import css from './LandingPage.module.css';
import testImage from '../LandingPage/SectionFeaturedListing/test.jpg';
import image1 from './testImage/1.png';
import image2 from './testImage/2.png';
import image3 from './testImage/3.png';
import image4 from './testImage/4.png';
import image5 from './testImage/5.png';
import image6 from './testImage/6.png';
import NYTBadge from '../../../src/assets/NYT Badge.png'
// Import logos for press section
import nytIcon from '../../assets/NYT Logo.png'; 
import businessInsiderIcon from '../../assets/BI Logo.jpeg'; 
import barstoolIcon from '../../assets/Barstool Sports Logo.png'; 
import usaTodayIcon from '../../assets/usa-today-seeklogo.png';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SignupModal from '../../components/Topbar/SignupModal';
import { loadData } from './LandingPage.duck';

// Loyalty Test in the Press component
const LoyaltyTestInPress = () => {
  const pressQuotes = [
    {
      source: 'New York Times',
      quote: '"All the arrangements were made through Loyalty-Test, a service that allows people to hire "testers" to flirt with their significant others online to see whether they respond to the romantic advances or remain faithful."',
      url: 'https://www.nytimes.com/2023/08/28/style/loyalty-test-infidelity-cheating.html',
      icon: nytIcon,
    },
    {
      source: 'Business Insider',
      quote: '"One woman who paid to test her partner told Business Insider she wished she had used the service sooner."',
      url: 'https://www.businessinsider.com/loyalty-test-cheating-partner-relationship-advice-2024-12',
      icon: businessInsiderIcon,
    },
    {
      source: 'Barstool Sports',
      quote: '"A Service Where You Can Hire A College Running Back To Test Your Girlfriend"',
      url: 'https://www.barstoolsports.com/blog/3481642/for-gods-sake-heres-a-service-where-you-can-hire-a-college-running-back-to-lure-your-girlfriend-into-cheating-on-you',
      icon: barstoolIcon,
    },
    {
      source: 'USA Today',
      quote: '"A website where people offer to "test" each other\'s significant others by flirting with them, sometimes over social media, sometimes in person. One user was so impressed that she booked twice."',
      url: 'https://www.usatoday.com/story/life/health-wellness/2025/03/27/loyalty-test-cheating/82616742007/',
      icon: usaTodayIcon,
    },
  ];

  return (
    <div className={css.pressSection}>
      <h2 className={css.pressTitle}>Loyalty Test in the Press</h2>
      <div className={css.pressQuotesContainer}>
        {pressQuotes.map((item, index) => (
          <a 
            key={index}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className={css.pressQuoteCard}
          >
            <div className={css.pressQuoteContent}>
              <div className={css.pressIconContainer}>
                <img src={item.icon} alt={`${item.source} icon`} className={css.pressIcon} />
              </div>
              <div className={css.pressSourceName}>{item.source}</div>
              <p className={css.pressQuoteText}>{item.quote}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export const LandingPageComponent = props => {
  const imageRef = React.useRef(null);
  const heroRef = React.useRef(null);
  
  const [hasMounted, setHasMounted] = useState(false);
  const [topbarHeight, setTopbarHeight] = useState(0);
  const dispatch = useDispatch();

  // Set hasMounted to true after component mounts
  useEffect(() => {
    setHasMounted(true);
    
    // Get topbar height using DOM query instead of ref
    const topbarElement = document.querySelector('.Topbar');
    if (topbarElement) {
      setTopbarHeight(topbarElement.offsetHeight);
    }
    
    // Load featured listings data every time the component mounts
    dispatch(loadData());
    
    return () => setHasMounted(false);
  }, [dispatch]);

  // Handle image positioning with a memoized effect to prevent unnecessary calculations
  useEffect(() => {
    if (imageRef.current && hasMounted) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      
      // Use the state variable instead of ref
      const heroHeight = heroRef.current ? heroRef.current.offsetHeight : 0;
      const imageHeight = imageRef.current ? imageRef.current.offsetHeight: 0;

      // Calculate the space between topbar and hero.
      const space = (heroHeight - topbarHeight) / 2;

      // Calculate the top position by adding the height of the topbar to half of the remaining space
      // after subtracting the height of the image (this will center the image between the two components).
      const topPosition = topbarHeight + (space - imageHeight / 2);

      // Adjust the position based on screen size
      if(isMobile){
        imageRef.current.style.left = width / 40 + 'px';
        imageRef.current.style.top = height / 8 + 'px';
      } else {
        imageRef.current.style.left = width / 35 + 'px';
        imageRef.current.style.top = topPosition / 4 + 'px';
      }
    }
  }, [hasMounted, topbarHeight]);

  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  } = props;
  
  const codeMaybe = new URLSearchParams(location.search).get('code');

  if (codeMaybe) return <InstaConnectForm code={codeMaybe} />;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const test = [image1, image2, image3, image4, image5, image6, testImage];
  
  // Memoize the featured listings section to prevent unnecessary re-renders
  const featuredListingsSection = useMemo(() => (
    <div className={css.featuredListings}>
      <SectionFeaturedListing
        loading={productsLoading}
        error={productsError}
        products={products}
      />
    </div>
  ), [productsLoading, productsError, products]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      {/* Gradient Background */}
      <div className={css.gradientBackground}></div>
      
      {/* Floating Circles */}
      <div className={css.floatingCircle}></div>
      <div className={css.floatingCircle}></div>
      <div className={css.floatingCircle}></div>
      <div className={css.floatingCircle}></div>
      
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isLandingPage={true} />
          <a 
            href="https://www.nytimes.com/2023/08/28/style/loyalty-test-infidelity-cheating.html" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Read about us in the New York Times"
          >
            <img 
              ref={imageRef} 
              src={NYTBadge} 
              alt="New York Times Badge" 
              className={css.yourImageClassName} 
            />
          </a>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div ref={heroRef} className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              products={products}
            />
          </div>

          <section aria-labelledby="featured-testers-heading">
            <div className={css.srOnly} id="featured-testers-heading">Featured Testers</div>
            {featuredListingsSection}
          </section>
          
          <section aria-labelledby="loyalty-test-press-heading">
            <div className={css.srOnly} id="loyalty-test-press-heading">Loyalty Test in the Press</div>
            <LoyaltyTestInPress />
          </section>
          
          <section aria-labelledby="customer-reviews-heading">
            <div className={css.srOnly} id="customer-reviews-heading">Customer Reviews</div>
            <ReviewBox className={css.reviewBox} />
          </section>
          
          <section aria-labelledby="loyalty-test-info-heading">
            <div className={css.srOnly} id="loyalty-test-info-heading">Loyalty Test Information</div>
            <SectionLoyaltyTestInfo className={css.loyaltyTestInfo} />
          </section>
          
          <section aria-labelledby="faq-heading">
            <div className={css.srOnly} id="faq-heading">Frequently Asked Questions</div>
            <SectionFAQ className={css.faqSection} />
          </section>
          
          {location?.state?.showModal ? <SignupModal /> : null}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Handle the case when state might be partially reset after logout
  if (!state) return {
    scrollingDisabled: false,
    currentUserListing: null,
    currentUserListingFetched: false,
    products: [],
    productsLoading: false,
    productsError: null,
  };

  const { currentUserListing, currentUserListingFetched } = state.user || {};
  const { productIds = [], productsLoading, productsError } = state.LandingPage || {};
  
  // Only try to map products if productIds exists and is an array
  const products = Array.isArray(productIds) 
    ? productIds.map(t => {
        if (!t) return null;
        const ref = { id: t, type: 'listing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings?.length === 1 ? listings[0] : null;
      }).filter(Boolean) // Filter out null values
    : [];

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './SectionLoyaltyTestInfo.module.css';
import { NamedLink } from '../../../components';

// SEO-friendly image imports from public directory
// Using relative paths to the public directory with SEO-friendly filenames
const loyaltyTestAnxietyReliefImage = '/static/images/blog/loyalty-test-anxiety-relief.png';
const relationshipTrustIssuesImage = '/static/images/blog/relationship-trust-issues.png';
const loyaltyTestConclusionImage = '/static/images/blog/loyalty-test-conclusion.png';
const relationshipUnderstandingImage = '/static/images/blog/relationship-understanding.png';

const SectionLoyaltyTestInfo = props => {
  const { rootClassName, className } = props;
  // Add inline style to force spacing and centering
  const containerStyle = {
    marginTop: '60px',
    width: '100%',
    maxWidth: '1128px',
    marginLeft: 'auto',
    marginRight: 'auto'
  };
  
  const classes = classNames(rootClassName || css.root, className);

  // Define the sections with their content
  const sections = [
    {
      title: "Why Should You Loyalty Test Your Partner?",
      text: [
        "We have found that almost every one of our customers has come away from a loyalty test with a better understanding of themselves and their relationship. A successful loyalty test can usher in an amazing era of trust in your relationship. A failed loyalty test can let you know that your partner isn't the one for you or perhaps that you may have to have a conversation with your partner about your boundaries and expectations. Join the thousands of men and women who have taken the step to a better relationship."
      ],
      image: relationshipUnderstandingImage,
      alt: "Better relationship understanding through loyalty testing",
      link: {
        name: "ListingPage",
        params: { id: "67b56cc3-7bcb-4ec5-9be7-66b476ee2cbe", slug: "minkaxox" }
      },
      tester: {
        name: "Minka",
        role: "Professional Tester",
        description: "5-star rated with 200+ successful tests",
        age: 33,
        location: "Chicago, IL"
      }
    },
    {
      title: "Reduce Your Stress and Anxiety with a Loyalty Test",
      text: [
        "A loyalty test is one of the best ways to relieve relationship anxiety and ease doubts in your relationships, but getting one can often be difficult and nerve-wracking. Loyalty-test's vetted and experienced testers are here to help you through the process. We pride ourselves on being discreet and thorough, ensuring that your test remains private.",
        "Hire one of our actresses, models, influencers, or athletes to DM your significant other. They will keep you updated every step of the process and you can give them as specific instructions as needed."
      ],
      image: loyaltyTestAnxietyReliefImage,
      alt: "Reduce relationship anxiety with loyalty tests",
      link: {
        name: "ListingPage",
        params: { id: "66bcf36e-65cf-4183-a266-7bfe89f21af1", slug: "jennifer" }
      },
      tester: {
        name: "Jennifer",
        role: "Professional Model",
        description: "Experienced loyalty tester with 100+ successful tests",
        age: 30,
        location: "Toronto, CA"
      }
    },
    {
      title: "Beat That Nagging Feeling You've Been Having",
      text: [
        "Trust issues can put a major strain on your relationship. Next time you feel like you're having a nagging feeling about your partner, use loyalty-test to hire someone to ease that doubt. There are dozens of reviewed and vetted testers ready to help you every step of the way."
      ],
      image: relationshipTrustIssuesImage,
      alt: "Overcome relationship trust issues and doubts",
      link: {
        name: "ListingPage",
        params: { id: "64fb6375-e423-49be-bd4c-683270492285", slug: "ari-loyaltytest" }
      },
      tester: {
        name: "Ari",
        role: "Part-Time Tester",
        description: "Specializes in Instagram DM loyalty tests",
        age: 29,
        location: "USA"
      }
    },
    {
      title: "Use a Loyalty Test to Come to a Conclusion",
      text: [
        "When your significant other is doing sketchy things, it can be a drain on your mental health. With a loyalty test, you can hire someone to DM them today. If they block or don't respond back, you can be confident that your partner is loyal. If they flirt back or make plans to meet up, you can redirect your time and energy to yourself or someone who appreciates and respects your boundaries."
      ],
      image: loyaltyTestConclusionImage,
      alt: "Reach a conclusion about your relationship with loyalty testing",
      link: {
        name: "ListingPage",
        params: { id: "65fa5ff6-ab3c-49b1-9f2f-7d709d236cf9", slug: "laribaby" }
      },
      tester: {
        name: "Lari",
        role: "Social Media Personality",
        description: "Fast responses and great reviews",
        age: 22,
        location: "Petaluma, CA"
      }
    }
  ];

  return (
    // Apply the inline style directly to ensure it takes effect
    <div className={classes} style={containerStyle}>
      {sections.map((section, index) => {
        const isEven = index % 2 === 0;
        
        // For desktop: even-indexed sections (0, 2) have image on left, odd-indexed (1, 3) have image on right
        // For mobile: CSS handles the ordering to ensure image always comes first
        return (
          <div key={index} className={isEven ? css.sectionImageLeft : css.sectionImageRight}>
            {/* Image column */}
            <div className={css.imageColumn}>
              <NamedLink 
                name={section.link.name} 
                params={section.link.params}
                className={css.imageLink}
              >
                <div className={css.imageContainer}>
                  <img 
                    src={section.image} 
                    alt={section.alt} 
                    className={css.image}
                    width="600"
                    height="450"
                    loading="lazy"
                  />
                  {/* Tester information overlay - now always visible */}
                  <div className={css.testerInfoOverlay}>
                    <div className={css.testerName}>{section.tester.name}</div>
                    <div className={css.testerAgeLocation}>{section.tester.age} • {section.tester.location}</div>
                    <div className={css.testerRole}>{section.tester.role}</div>
                    <div className={css.testerDescription}>{section.tester.description}</div>
                  </div>
                </div>
              </NamedLink>
            </div>
            
            {/* Text column */}
            <div className={css.textColumn}>
              <h3 className={css.sectionTitle}>{section.title}</h3>
              {section.text.map((paragraph, i) => (
                <p key={i} className={css.sectionText}>{paragraph}</p>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

SectionLoyaltyTestInfo.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionLoyaltyTestInfo.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
};

export default SectionLoyaltyTestInfo; 
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import css from './SectionFAQ.module.css';

const FaqItem = ({ question, children, isOpen, onClick }) => {
  return (
    <div className={`${css.faqItem} ${isOpen ? css.open : ''}`}>
      <button 
        className={css.questionButton} 
        onClick={onClick}
        aria-expanded={isOpen}
      >
        <h3 className={css.question}>{question}</h3>
        <span className={css.arrow}></span>
      </button>
      <div className={css.answerContainer} aria-hidden={!isOpen}>
        {children}
      </div>
    </div>
  );
};

const SectionFAQ = props => {
  const { className } = props;
  // Initialize with an empty array so all questions are closed by default
  const [openItems, setOpenItems] = useState([]); 
  
  const toggleItem = (index) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter(item => item !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };
  
  const isItemOpen = (index) => openItems.includes(index);
  
  return (
    <div className={className}>
      <div className={css.container}>
        <h2 className={css.title}>Frequently Asked Questions</h2>
        
        <FaqItem 
          question="What is a loyalty test?" 
          isOpen={isItemOpen(0)}
          onClick={() => toggleItem(0)}
        >
          <p className={css.answer}>
            A loyalty test, a service offered by Loyalty-Test.com, is a method to determine if a partner is faithful and/or loyal. Often performed via social media, these tests help you understand where you stand in a relationship by uncovering any ill intentions by a partner.
          </p>
          <p className={css.answer}>
            A loyalty test can be performed through various communication channels such as texting, Instagram DMs, Facebook Messenger, or FaceTime. You can specify your preferred method to your tester.
          </p>
        </FaqItem>
        
        <FaqItem 
          question="Should I do a loyalty test on my partner?" 
          isOpen={isItemOpen(1)}
          onClick={() => toggleItem(1)}
        >
          <p className={css.answer}>
            A loyalty test can help address any anxiety with your partner by confirming or dispelling your suspicions. If your partner has been acting strange or exhibiting specific behaviors, you should definitely consider doing a loyalty test on them.
          </p>
          <p className={css.answer}>
            Some concerning behaviors include increased secrecy or privacy, sudden changes in routine, reduced intimacy, defensive behavior, excessive texting, or sudden shifts in interests.
          </p>
        </FaqItem>
        
        <FaqItem 
          question="How do I test if my partner is loyal?" 
          isOpen={isItemOpen(2)}
          onClick={() => toggleItem(2)}
        >
          <p className={css.answer}>
            One way to do so is to hire a loyalty tester from our site. After selecting a tester, you can provide them with your preferred method for contacting your significant other, along with any additional details you think might be helpful.
          </p>
          <p className={css.answer}>
            Feel free to be as specific as you want—for instance, suggesting where the tester might have seen your partner or why they are showing up as a recommended friend. Your tester will keep you updated every step of the way.
          </p>
        </FaqItem>
        
        <FaqItem 
          question="How can a loyalty test benefit my relationship?" 
          isOpen={isItemOpen(3)}
          onClick={() => toggleItem(3)}
        >
          <p className={css.answer}>
            A loyalty test can help your relationship by:
          </p>
          <ul className={css.benefitsList}>
            <li>Reducing the time you spend anxiously thinking about your partner</li>
            <li>Allowing you to ease your relationship anxiety</li>
            <li>Helping you understand your boundaries better in your relationship</li>
          </ul>
        </FaqItem>
        
        <div className={css.viewMoreContainer}>
          <Link to="/ChooseTester" className={css.viewMoreLink}>
            How it Works
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionFAQ; 